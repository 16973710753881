import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'
import { CommonProps } from './Button.types'

let { color, fontColor, fontSize, backgroundColor, borderColor, radius, size } =
  mTheme

export const primary: StyleWithOptions<CommonProps> = (props) => {
  const buttonColor =
    (props.plain && props.critical && color.alert) ||
    (props.hollow && props.primary && color.primary) ||
    (props.hollow && props.secondary && color.primary) ||
    fontColor.plain.primary

  return css({
    display: 'flex',
    gap: size.XS,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    outline: 'none',
    fontSize: fontSize.M,
    color: buttonColor,
    fontWeight: props.plain ? 'normal' : 'bold',
    backgroundColor:
      ((props.hollow || props.plain) && 'transparent') ||
      (props.disabled && backgroundColor.disabled) ||
      (props.primary && backgroundColor.brand) ||
      (props.secondary && backgroundColor.secondary) ||
      (props.critical && color.alert) ||
      backgroundColor.card,
    opacity: props.disabled ? 0.5 : 1,
    border: props.plain ? 'none' : `1.5px solid`,
    borderColor:
      (props.hollow && props.primary && color.primary) ||
      (props.hollow && props.secondary && color.primary) ||
      (props.hollow && borderColor.secondary) ||
      'transparent',
    borderRadius: radius.primary,
    boxSizing: 'border-box',
    width: props.fullWidth ? '100%' : 'auto',
    padding: size.S,

    lineHeight: 1,

    '&, &:hover, &:visited': {
      color: buttonColor,
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&:active': {
      opacity: 0.5,
    },

    ':hover': {
      textDecoration: 'none',
      color:
        (props.hollow && props.primary && color.primary) ||
        (props.hollow && props.secondary && color.primary) ||
        fontColor.plain.primary,
    },
    ':focus-visible': {
      outline: `2px solid ${color.focusRing}`,
      outlineOffset: size.XXS,
      borderRadius: radius.secondary,
    },
  })
}
