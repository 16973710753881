import pick from 'lodash-es/pick'
import { Link } from '@remix-run/react'

import * as styles from './Button.styles'
import {
  ButtonAsLinkProps,
  ButtonAsAnchorProps,
  ButtonProps,
} from './Button.types'
import LoadingSpinner from '../LoadingSpinner'

const buttonPropNames = [
  'onClick',
  'type',
  'className',
  'data-testid',
  'disabled',
  'tabIndex',
] as const

const Button: React.FC<ButtonProps> = ({
  children,
  loadingChildren,
  composeCss,
  ...props
}) => {
  return (
    <button
      css={[styles.primary(props), composeCss]}
      {...pick(props, buttonPropNames)}
    >
      {props.loading ? (
        <>
          {loadingChildren} <LoadingSpinner compact />
        </>
      ) : (
        children
      )}
    </button>
  )
}

const anchorPropNames = [
  ...buttonPropNames,
  'href',
  'rel',
  'target',
  'tabIndex',
] as const

export const ButtonAsAnchor: React.FC<ButtonAsAnchorProps> = ({
  children,
  composeCss,
  ...props
}) => {
  return (
    <a
      css={[styles.primary(props), composeCss]}
      {...pick(props, anchorPropNames)}
    >
      {children}
    </a>
  )
}

const linkPropNames = ['onClick', 'type', 'className', 'to'] as const

export const ButtonAsLink: React.FC<ButtonAsLinkProps> = ({
  children,
  composeCss,
  ...props
}) => {
  return (
    <Link
      css={[styles.primary(props), composeCss]}
      {...pick(props, linkPropNames)}
    >
      {children}
    </Link>
  )
}

export default Button
